document.addEventListener("DOMContentLoaded", function () {
    /*** Scroll-based Behavior for Multiple Elements ***/
    const scrollBgElements = document.querySelectorAll(".scroll-anim");
    const SCROLL_THRESHOLD = 100;
  
    if (scrollBgElements.length > 0) {
      const updateScrollClass = () => {
        scrollBgElements.forEach((element) => {
          const elementOffset = element.getBoundingClientRect().top + window.scrollY;
          const scrolled = window.scrollY > SCROLL_THRESHOLD && window.scrollY > elementOffset - window.innerHeight;
          element.classList.toggle("scrolled", scrolled);
        });
      };
  
      window.addEventListener("scroll", updateScrollClass);
    }
});